// noinspection JSUnresolvedFunction

/**
 * The main class of the JavaScript.
 */
class Main {

	/**
	 * The cookie name.
	 *
	 * @type {string}
	 */
	static COOKIE_NAME = 'esodb_cookie_compliance';


	/**
	 * The Main constructor.
	 */
	constructor() {

		this.registerOnClick('#cookieComplianceBtn', this.setCookieCompliance);
	}

	/**
	 * Register for a click event with an event handler.
	 *
	 * @param {string} selector The selector.
	 * @param {function} callback The callback.
	 */
	registerOnClick(selector, callback) {
		let input = document.querySelector(selector);
		if(input !== null) {
			input.addEventListener('click', (e) => {
				callback(e);
			});
		}
	}

	/**
	 * Sets the cookie compliance cookie and fades out the info bar.
	 */
	setCookieCompliance() {
		Cookie.set(Main.COOKIE_NAME, '1', 365, Cookie.getCookieHostname(), true);
		document.querySelector('#cookieCompliance').style.display = 'none';
	}
}

/**
 * If the document is loaded.
 */
document.addEventListener('DOMContentLoaded', () => {
	new Main();
});
